
@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Black Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Bold Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Black Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Bold Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Regular Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Condensed Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Extra Condensed Black Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Extra Condensed Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Extra Condensed Bold Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Extra Condensed Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Extra Condensed Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  fontFamily: 'ProximaNovaCond';
  src: url('assets/fonts/Proxima Nova Alt Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
body {
  margin: 0;
  /* fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  fontFamily: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 100% !important; /* 根据你的需求设置值 */
  }
}